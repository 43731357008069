<template>
  <div class="hello">
    <div class="name">
      <div class="letter">
        <img src="../assets/img/K.png" class="letter-image">
      </div>
      <div class="text">
        <div class="text-biggest">
          Bright
        </div>
        <div class="text-big">
          O melhor amigo do seu futuro
        </div>
        <div class="text-normal">
          <!-- Gerencia sua previdência de forma simples e segura com a Bright. -->
          Baixe GRÁTIS para iPhone e Android.
        </div>
        <div class="text-big">
          Características
        </div>
        <div class="text-normal">
          Bright é uma alternativa simples e segura para a previdência na sua empresa.
          - Crie uma lista simples de despesas - mantenha o controle do saldo
          - Restituição de imposto para você e a sua empresa
          - Tranquilidade para empresa e colaboradores
        </div>
      </div>
      <div class="letter">
        <img src="../assets/img/Y.png" class="letter-image">
      </div>
    </div>
    <div class="menu">
      <div class="menu-item menu-left">
        2022 © Johannes van Leeuwen
      </div>
      <div class="menu-item">
        <div class="menu-text">
          Apoio & suporte
        </div>
      </div>
      <div class="menu-item">
        <span>|</span>
      </div>
      <div class="menu-item">
        <div class="menu-text">
          Política de privacidade
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text {
  font-size: 20px;
  color: #818181;
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 100vw;
  height: calc(100vh - 60px);
}
.text-normal {
  font-size: 20px;
}
.text-big {
  font-size: 36px;
  /* font-weight: 5; */
}
.text-biggest {
  font-size: 100px;
  font-weight: lighter;
  color: #4682b4;
}

.letter {
  margin: .8%;
  filter: drop-shadow(0px 0px 7px rgba(150, 150, 150, .7));
}
.letter-image {
  width: 100%;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  height: 60px;
}
.menu-item {
  font-size: 20px;
  color: #818181;
  flex: 1;
  text-align: center;
  /* text-align: right; */
  display: flex;
  justify-content: center;
  /* justify-content: right; */
  align-items: center;
  /* align-items: right; */
}
.menu-text{
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  width: fit-content;
}
.menu-text:hover {
  border-bottom: 2px solid;
}

</style>
