<template>
  <div id="app">
    <kylie-ying/>
  </div>
</template>

<script>
import KylieYing from './components/KylieYing.vue'

export default {
  name: 'App',
  components: {
    KylieYing }
}
</script>

<style>
#app {
}

body {
}
</style>
